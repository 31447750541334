export enum Routes {
    // Prefixes
    PrefixAdmin = '/admin/',
    PrefixPanel = '/panel/',
    PrefixAccount = '/konto/',

    // Public routes
    PublicHome = '/',
    PublicMenZoneSlug = '/strefa-mezczyzn/',
    PublicWomenZoneSlug = '/strefa-kobiet/',
    PublicProduct = '/[genderZoneSlug]/[slug]/',
    PublicSpecialOffers = '/oferty-specjalne/',
    PublicSpecialOffersMen = '/oferty-specjalne-dla-mezczyzn/',
    PublicSpecialOffersWomen = '/oferty-specjalne-dla-kobiet/',
    PublicSpecialOffersMenSlug = '/oferty-specjalne-dla-mezczyzn/[slug]/',
    PublicSpecialOffersWomenSlug = '/oferty-specjalne-dla-kobiet/[slug]/',
    PublicSpecialOffer = '/oferty-specjalne/[slug]/',
    PublicSpecialOffersBundle = '/oferty-specjalne-flexy/[slug]',
    PublicBlog = '/blog/',
    PublicBlogArticle = '/blog/[articleCategorySlug]/[slug]/',
    PublicBlogCategory = '/blog/[articleCategorySlug]/',
    PublicLocation = '/salony/[slug]/',
    PublicLocations = '/salony/',
    PublicPriceList = '/cennik/',
    PublicPriceListBundles = '/oferta-flexi/',
    PublicPriceListTreatmentsPackages = '/cennik/zabiegi-i-pakiety/',
    PublicPriceListTreatmentsEndermology = '/cennik/zabiegi-endermologii/',
    PublicPriceListTreatmentsEmSpec = '/cennik/zabiegi-elektrostymulacji/',
    PublicPriceListTreatmentsHydrogen = '/cennik/zabiegi-oczyszanie-wodorowe/',
    PublicPriceListPackagesEndermology = '/cennik/pakiety-endermologii/',
    PublicPriceListPackagesEmSpec = '/cennik/pakiety-elektrostymulacji/',
    PublicPriceListPackagesHydrogen = '/cennik/pakiety-oczyszczanie-wodorowe/',
    PublicTenForAnimal = '/10dlazwierzaka/',
    PublicEndermology = '/endermologia/',
    PublicEmSpec = '/elektrostymulacja/',
    PublicHydrogen = '/oczyszczanie-wodorowe/',
    PublicFaq = '/faq/',
    PublicAbout = '/o-nas/',
    PublicReviewerClub = '/klub-recenzentek/',
    PublicCart = '/koszyk/',
    PublicCartReservation = '/koszyk/rezerwacja/',
    PublicCartSummary = '/koszyk/podsumowanie/',
    PublicTechnology = '/technologia/',
    PublicPersonalDataProtection = '/ochrona-danych-osobowych/',
    PublicLocationRegulations = '/regulamin-korzystania-z-uslug-salonow/',
    PublicMenZone = '/strefa-mezczyzn/',
    PublicWemanZone = '/strefa-kobiet/',
    PublicReviews = '/opinie/',
    PublicCheckout = '/podsumowanie/',
    PublicContact = '/kontakt/',
    PublicPaymentSummary = '/platnosc/podsumowanie/[id]/',
    PublicPaymentConfirm = '/platnosc/potwierdzenie/[id]/',
    PublicPrivacyPolicy = '/polityka-prywatnosci/',
    PublicBusinessIdea = '/pomysl-na-biznes/',
    PublicApplication = '/aplikacja/',
    PublicApplicationRegulations = '/regulamin-aplikacja/',
    PublicRegulations = '/regulamin/',
    PublicVouchers = '/vouchery/',
    PublicBundle = '/paczki/[slug]/',

    // Landing pages route
    LandingPages = '/lp/[slug]',

    // Panel routes
    Panel = '/panel/',
    PanelSettings = '/panel/ustawienia/',
    PanelMyVisits = '/panel/zamowienia/',
    PanelMyOrders = '/panel/moje-zamowienia/',
    PanelSpecialOffers = '/panel/oferty-specjalne/',
    PanelBeautyClub = '/panel/beauty-club/',
    PanelContact = '/panel/kontakt/',
    PanelInfluencer = '/panel/influencer/',
    PanelPrivacyProtection = '/panel/ochrona-prywatnosci/',

    AccountLogin = '/konto/logowanie/',
    AccountRegister = '/konto/rejestracja/',
    AccountPasswordReset = '/konto/przypomnij-haslo/',
    AccountPasswordChange = '/konto/zmiana-hasla/',
    AccountActivation = '/konto/aktywacja/',
}
